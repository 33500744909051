body {
  margin: 0;
  background: rgba(145,10,244,1);
  font-family: 'Inknut Antiqua', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  font-size: 14px;
  line-height: 20px;



}
.half{
  width: 50%;
 
}



.content{
  
  text-align: left;
 
}
label{
  text-align: left;
  width: 100%;
}

textarea{
  background-color: #333;
  border: none;
  width: 100%;
  font-size: 1.2em;
  resize: none;
  color: rgba(145,10,244,1);
  padding: 0;
}
.shadow{
  width: 100%;
  height: 30px;
  position: absolute;
  bottom: 5px;
  
  border-radius: 90%;

}
.animation{
  position: fixed;
  right: 0;
  top: 0;
  width: 50%;
  height: 100vh;
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%,  rgba(145,10,244,1) 100%);

 
}
#circle{
  transform: rotate(90deg);
  color: blue;
}
.animation img{
  max-width: 100%;
  max-height: 100vh;
  margin-left: 50%;
  transform: translateX(-50%);
}

h1{
  padding: 10px;
  line-height: 1.5em;
  text-transform: uppercase;
  text-align: left;
}
p{
  padding: 10px;
  text-align: left;
}
p span{
  display: block;
  font-size: 0.8em
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul{
  margin: 0;
  padding-left: 10px;
  text-align: left;
  width: auto;
  height: auto;
}
li{
  display: inline-block;
  list-style: none;
  width: auto;
  border: white 1px solid;
  padding: 2px;
  
}
li:hover{
  background: #222;
  cursor: crosshair
}

button{
  width: 100%;
  background: transparent;
  border: white 2px solid;
  cursor: pointer;
  color: white;
  text-transform: uppercase;
  font-size: 2em;
  font-family: 'Inknut Antiqua', serif;
}

#suggest{
  min-height: unset;
}

.label{
  padding: 0px 10px;
  
}


@media only screen and (max-width: 768px) {

 .half{
   width: 100%;
 }
 .animation{
   position: relative;
    height: auto;
 }
 h1{
   font-size: 1.2em
 }
}